<template>
    <div>
    <div class="RFIreportwidth">
        <div class="fixdheader">
            <span class="headerstyle">RFI Reporting / Uploading</span>
            <hr class="hrstyle" />
            <!-- <el-row>
                <el-col :span="24"> -->
                    <!-- <selectpart 
                    :Similar="Similar"
                     @projectpartchange="cmpprojectsectionchange">
                    </selectpart> -->
                    <!-- <div class="fixwidth hrspacebetween">
                    <el-autocomplete
                    class="inline-input fullwidth bold padding2px"
                    v-model="project.projectname"
                    popper-class="morewidth-autocomplete-project"
                    :fetch-suggestions="searchProject"
                    
                    value-key="projectname"
                    
                    :placeholder="'Please select Project'">
                    <el-button slot="suffix" @click="closeproject" type="text" size="mini">&times;</el-button>
                    <template slot-scope="{ item }">
                        <span style="float: left">{{ item.projectname }}</span>
                        <span style="float: right">{{ item.accessedon  }}</span> 
                    </template>
                </el-autocomplete>
            </div> -->
            <el-row>
                <el-col :span="10">
                <el-autocomplete
                      class="inline-input fullwidth bold padding2px"
                      v-model="project.projectname"
                      popper-class="morewidth-autocomplete-project"
                      :fetch-suggestions="searchProject"
                      @select="selectProject"
                      value-key="projectname"
                      :disabled="false"
                      :placeholder="'Please select Project'">
                      <el-button slot="suffix" @click="closeproject" type="text" size="mini">&times;</el-button>
                      <template slot-scope="{ item }">
                          <span style="float: left">{{ item.projectname }}</span>
                          <span style="float: right">{{ item.accessedon | dateformat }}</span>
                      </template>
                  </el-autocomplete>
                  <div class="left hrspacebetween " >
                    <el-select
                        v-model="projectPartId"
                        filterable
                        placeholder="Project Parts"
                        style="width: 224px;"
                        class="fullwidth padding2px bold"
                        @change="changepart">
                        <el-option
                            v-for="item in ProjectParts"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                    </div>
                    <span>
                        <el-select
                        v-model="sectionid"
                        filterable
                        placeholder="RFI Section"
                        class="padding2px bold"
                        style="width: 224px;"
                        :clearable="true"
                        @change="sectionchange">
                        <el-option
                            v-for="item in sections"
                            :key="item.id"
                            :label="item.section"
                            :value="item.id">
                        </el-option>
                    </el-select>
                        <!-- <el-select
                        v-model="sectionid"
                        filterable
                        style="width: 224px;"
                        placeholder="Section"
                        class="padding2px bold"
                        @change="sectionchange">
                        <el-option
                            v-for="item in getprojectsection"
                            :key="item.id"
                            :label="item.sectiondisplay"
                            :value="item.id">
                        </el-option>
                    </el-select> -->
                    
                    <!-- <el-select
                        v-model="sectionid"
                        filterable
                        style="width: 289px;"
                        placeholder="RFI Section"
                        class="padding2px bold"
                        @change="sectionchange">
                        <el-option
                            v-for="item in rfisections"
                            :key="item"
                            :label="item"
                            :value="item">
                        </el-option>
                    </el-select> -->
                        </span>
                    </el-col>
                    <el-col :span="14">
                        
                            <el-button v-if="canshowreport" style="bottom: 0;right: 0px;position: absolute;margin-right: 5px"  @click="addrfilibrary" type="primary"  >
                                RFI Library
                            </el-button>
                    </el-col>
                </el-row>
                <!-- </el-col>
            </el-row> -->
            <hr class="hrstyle" />
            <div v-if="canshowreport">
                <div class="gridcontainer">
                    <span class="colstyle">
                        <el-select v-model="nrmsection" clearable @change="getviewreport" placeholder="NRM Section">
                            <el-option v-for="item in versions" :key="item.nrmsection" :label="item.nrmsection"
                                    :value="item.nrmsection">
                            </el-option>
                        </el-select>
                    </span>
                    <span class="colstyle">
                        <el-select v-model="Resolvedfilter" @change="getviewreport" clearable placeholder="Status">
                            <el-option v-for="item in ['All', 'Unresolved', 'Resolved']" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </span>
                    <span class="colstyle">
                        <el-select v-model="consultationfilter" clearable @change="getviewreport" placeholder="Classification">
                            <el-option v-for="item in consultationarray" :key="item.value" :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </span>
                    <span class="colstyle">
                        <el-button :disabled="!editpermission" @click="getviewreport"  type="primary">View Report</el-button>
                    </span>
                </div>
                <!-- <el-row justify="space-between" :gutter="5">
                    <el-col :span="6">
                        <el-select v-model="nrmsection" clearable @change="getviewreport" placeholder="NRM / Bill Sectoins">
                            <el-option v-for="item in versions" :key="item.nrmsection" :label="item.nrmsection"
                                    :value="item.nrmsection">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="6">
                        <el-select v-model="Resolvedfilter" @change="getviewreport" clearable>
                            <el-option v-for="item in ['All', 'Unresolved', 'Resolved']" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="6">
                        <el-select v-model="consultationfilter" clearable @change="getviewreport" placeholder="Classification">
                            <el-option v-for="item in consultationarray" :key="item.value" :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="6">
                        <el-button :disabled="!editpermission" @click="getviewreport" class="fullwidth" type="primary">View Report</el-button>
                    </el-col>
                </el-row> -->
                <hr class="hrstyle" />
                <el-row>
                    <div class="left">
                        <div>
                        <i class="el-icon-caret-left" @click="setdate1('subtract')"></i>
                            <el-date-picker class="custom-date-picker" style="width: 102px !important;" v-model="dateselect1" type="date" placeholder="Pick a day">
                            </el-date-picker>
                            <i class="el-icon-caret-right" @click="setdate1('add')"></i>
                        </div>
                    </div>
                    <div class="floatright">
                        <span class="colstyle ">
                            <!-- editpermission -->
                            <el-upload ref="upload" action="" :disabled="!editpermission" :http-request="addAttachment"
                                :on-remove="deleteAttachment" :file-list="attachments" accept=".xlsx"
                                style="display: inline-block">
                                <el-button slot="trigger"   type="primary"  >
                                    Import Responses
                                </el-button>
                            </el-upload>
                        </span>
                        <span class="colstyle ">
                            <el-button   @click="addresponses"  type="primary">
                                <i v-if="canshowresponse" style="color: red;" class="el-icon-edit"></i>
                                Add Response/Action</el-button>
                        </span>    
                        <span class="colstyle ">
                            <el-button v-if="canexportreport" :disabled="!editpermission" @click="validationexport"  type="primary">Export RFI</el-button>
                        </span>
                        
                    </div>
                </el-row>
                <!-- <div class="gridcontainer">
                     <span class="colstyle ">
                        <div>
                        <i class="el-icon-caret-left" @click="setdate1('subtract')"></i>
                            <el-date-picker class="custom-date-picker" style="width: 102px !important;" v-model="dateselect1" type="date" placeholder="Pick a day">
                            </el-date-picker>
                            <i class="el-icon-caret-right" @click="setdate1('add')"></i>
                        </div>
                     </span>
                     <span class="colstyle ">
                        <el-upload ref="upload" action="" :disabled="!editpermission" :http-request="addAttachment"
                            :on-remove="deleteAttachment" :file-list="attachments" accept=".xlsx"
                            style="display: inline-block">
                            <el-button slot="trigger" class="floatright"  type="primary" :disabled="!editpermission" >
                                Import Responses
                            </el-button>
                        </el-upload>
                     </span>
                     
                     <span class="colstyle ">
                        <el-button class="floatright" :disabled="!editpermission" @click="exportxlsx"  type="primary">Export RFI</el-button>
                     </span>
                     <span class="colstyle ">
                        <el-button class="floatright" :disabled="!editpermission" @click="saverfireport"  type="success">Save</el-button>
                     </span>
                </div> -->
                <!-- <el-row justify="space-between" :gutter="5">
                    <el-col :span="6">
                        <el-row>
                            <i class="el-icon-caret-left" @click="setdate1('subtract')"></i>
                            <el-date-picker class="custom-date-picker" style="width: 102px !important;" v-model="dateselect1" type="date" placeholder="Pick a day">
                            </el-date-picker>
                            <i class="el-icon-caret-right" @click="setdate1('add')"></i>

                        </el-row>
                    </el-col>
                    <el-col :span="6">
                       
                        <el-upload ref="upload" action="" :disabled="!editpermission" :http-request="addAttachment"
                            :on-remove="deleteAttachment" :file-list="attachments" accept=".xlsx"
                            style="display: inline-block">
                            <el-button slot="trigger" class="fullwidth" type="primary" :disabled="!editpermission" >
                                Import Responses
                            </el-button>
                        </el-upload>
                        
                    </el-col>
                    <el-col :span="6">
                        <el-button :disabled="!editpermission" @click="saverfireport" class="fullwidth" type="primary">Add Responses</el-button>
                    </el-col>
                    <el-col :span="6">
                        <el-button :disabled="!editpermission" @click="exportxlsx" class="fullwidth" type="primary">Export RFI</el-button>
                    </el-col>
                </el-row> -->
                <hr class="hrstyle" />
                <el-row justify="space-between" :gutter="4">
                    <el-col :span="6">
                        <i class="el-icon-caret-left" @click="setdate2('subtract')"></i>
                        <el-date-picker class="custom-date-picker" style="width: 102px !important;" v-model="dateselect2" type="date" placeholder="Pick a day">
                        </el-date-picker>
                        <i class="el-icon-caret-right" @click="setdate2('add')"></i>
                    </el-col>
                    <el-col :span="7">
                        <el-select v-model="rfireportversion" @change="getviewreport" clearable class="fullwidth" placeholder="RFI Version">
                            <el-option v-for="item in rfireportversions" :key="item.id" :label="rfireportversionformat(item.version)" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="7">
                        <el-button :disabled="!editpermission" @click="savereportversion" class="fullwidth" icon="el-icon-circle-plus" type="primary">Create New
                            Version</el-button>
                    </el-col>
                    <el-col :span="4">
                        <el-button :disabled="!editpermission && cansave" @click="cellchanged('comms_rfireport_save',()=>{saverfireport()},false)  " class="fullwidth" type="success" icon="el-icon-circle-check">Save</el-button>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
    <el-row v-if="tabledata != undefined">
      
        <!-- <div v-if="tabledata.generalitems && tabledata.generalitems.length > 0">
            <el-table 
        :data="[{name:'General Items'}]"
        :border="true"
        :show-header="false"
        :row-style="{'font-weight':'800'}"
        style="width: 100%">
        <el-table-column type="expand" width="50">
                <template >
                    <el-table 
                    v-if="tabledata.generalitems && tabledata.generalitems.length > 0"
            :data="tabledata.generalitems"
            :border="true"
            style="width: 100%">
            <el-table-column
                prop="nrmsection"
                label="NRM Section"
                width="50">
                <template slot="header" >
                    NRM <br/> Section
                </template>
                <template slot-scope="scope">
                    {{ scope.row.nrmsection}}
                  
                </template>
            </el-table-column>
            <el-table-column
                prop="issue"
                label="Issue"
                width="180">
                <template slot-scope="scope">
                    <el-tooltip  effect="dark" content="" placement="top">
                    <div id="input_inner">
                        <div class="textstyle"> {{ scope.row.issue }}</div>
                       
                    </div>
                    <template slot="content">
                    <div> 
                        Created By : {{scope.row.createdbyuser.loginname != undefined ? scope.row.createdbyuser.loginname : ''}} {{ dateformat(scope.row.createdon) }} 
                        <br/>
                        Edited By : {{ scope.row.editedbyuser.loginname != undefined ? scope.row.editedbyuser.loginname : '' }}  {{ dateformat(scope.row.editedon) }} 
                    </div>
                    </template>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column
                prop="issue"
                label="Consultation"
                width="180">
                <template slot-scope="scope">
                    <div class="textstyle">{{ scope.row.consultation}}</div>
                   
                </template>
            </el-table-column>
            <el-table-column
                prop="resolvedon"
                label="Snip">
                <template slot-scope="scope">
                    <span style="width: 77%;" class="left">
                                <span v-for="svi in scope.row.ssv" :key="svi.id" >
                                  
                                    <el-popover placement="bottom" width="200" v-model="svi.mousehover" trigger="hover" >
                                        <div @click="showpreview(svi)">
                                            <span class="previewbox" v-if="svi.extention == 'xlsx'">
                                                <div class="UR-Link fontsize12">
                                                    {{ svi.name }}
                                                </div>
                                                <div class="center">
                                                    <i class="el-icon-document iconsize90"></i>
                                                </div>
                                            </span>
                                            <span v-if="svi.extention">
                                            <span
                                                v-if="svi.extention.startsWith('jpg') || svi.extention.startsWith('jpeg')  || svi.extention.startsWith('gif')  || svi.extention.startsWith('png')">
                                                <img :src="svi.link" class="previewbox" />
                                            </span>
                                            <span v-if=" svi.extention.startsWith('mp4')">
                                                <video controls class="previewbox">
                                                    <source :src="svi.url" type="video/mp4">
                                                    Your browser does not support the video tag.
                                                </video>
                                            </span>
                                            <span v-if=" svi.extention.startsWith('mp3')">
                                                <audio controls class="previewbox">
                                                    <source :src="svi.link" type="audio/ogg">
                                                    <source :src="svi.link" type="audio/mpeg">
                                                    Your browser does not support the audio element.
                                                </audio>
                                            </span>
                                        </span>
                                        </div>
                                        <span slot="reference" class="UR-Link marginl5 marginright5px fontsize12 " 
                                            @mouseenter="previewsnip(svi)"> <el-tag size="mini" style="white-space: normal;"> {{ svi.name }} </el-tag></span>
                                        </el-popover>
                                       
                                </span>
                            </span>
                </template>
            </el-table-column>
            <el-table-column
                prop="interimaction"
                label="Interim Action">
                <template slot-scope="scope">
                    <div class="textstyle"> {{ scope.row.interimaction}}</div>
                   
                </template>
            </el-table-column>
            <el-table-column
                prop="requiredaction"
                label="Required Action">
                <template slot-scope="scope">
                    <div class="textstyle"> {{ scope.row.requiredaction}} </div>
                   
                </template>
            </el-table-column>
            <el-table-column
                prop="response"
                label="Response">
                <template slot-scope="scope">
                    <el-tooltip  effect="dark" :content="dateformat(scope.row.responseon)" placement="top">
                        <div class="textstyle">{{ scope.row.response }}</div>
                   
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column
                prop="actiontaken"
                label="Action Taken">
                <template slot-scope="scope">
                    <el-tooltip  effect="dark" :content="'Action Taken By:'+ (scope.row.resolvedbyuser.loginname != undefined ? scope.row.resolvedbyuser.loginname : '') + '  '+ dateformat(scope.row.resolvedon)" placement="top">
                    
                        <div class="textstyle">{{ scope.row.actiontaken }}</div>
                   
                    </el-tooltip>
                </template>
            </el-table-column>
            
           
            </el-table>
                </template>
                </el-table-column>
                <el-table-column
                    prop="name"
                    label=""
                    >
                </el-table-column>
        </el-table>
        </div> -->
        <el-table v-if="tabledata.length > 0"
        :data="tabledata"
        :border="true"
        :expand-row-keys="expandedrows"
        row-key="expandid"
        class="rfitable"
        :show-header="false"
        :default-expand-all="true"
        :row-style="{'font-weight':'800'}"
        style="width: 100%">
            <el-table-column type="expand" width="50">
                <template slot-scope="sv">
                    <el-table
                       class="rfitable"
                        :data="getsectctionview(sv.row)"
                        :border="true"
                        :show-header="false"
                        :default-expand-all="true"
                        :row-class-name="sv.row.isgeneralitems == true ? 'hidden-row' : ''"
                        :row-style="{'font-weight':'700'}"
                        style="width: 100%">
                        <el-table-column type="expand">
                            <template slot-scope="rfi">
                                <el-table v-if="rfi.row.rfis.length > 0"
                                    class="rfitable"
                                    :data="rfi.row.rfis"
                                    :border="true"
                                    :row-class-name="tableRowClassName"
                                    :cell-style="tablecellClassName"
                                    style="width: 100%">
                                    <el-table-column
                                        prop="nrmsection"
                                        label="NRM Section"
                                        width="50">
                                        <template slot="header" >
                                            NRM <br/> Section
                                        </template>
                                        <template slot-scope="scope">
                                            {{ scope.row.nrmsection}}
                                            <!-- <el-input
                                                type="text"
                                                :rows="2"
                                                placeholder="Please issue"
                                                v-model="scope.row.nrmsection">
                                                </el-input> -->
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="issue"
                                        label="Issue"
                                        width="180">
                                        <template slot-scope="scope">
                                            <el-tooltip :disabled="scope.row.createdbyuser.loginname == undefined || scope.row.editedbyuser.loginname == undefined" effect="dark" content="" placement="top">
                                            <div id="input_inner">
                                               <div class="textstyle"> {{ scope.row.issue }}</div>
                                                <!-- <el-input
                                                type="textarea"
                                                :rows="5"
                                                class="inline-input fullwidth hvrtextarea"
                                                placeholder="Please issue"
                                                v-model="scope.row.issue">
                                                </el-input> -->
                                            </div>
                                            <template slot="content">
                                            <div> 
                                                Created By : {{scope.row.createdbyuser.loginname != undefined ? scope.row.createdbyuser.loginname : ''}} {{ dateformat(scope.row.createdon) }} 
                                                <br/>
                                                Edited By : {{ scope.row.editedbyuser.loginname != undefined ? scope.row.editedbyuser.loginname : '' }}  {{ dateformat(scope.row.editedon) }} 
                                            </div>
                                            </template>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="issue"
                                        label="Consultation"
                                        width="180">
                                        <template slot-scope="scope">
                                            <div class="textstyle">{{ scope.row.consultation}}</div>
                                            <!-- <el-input
                                                type="textarea"
                                                :rows="5"
                                                class="inline-input fullwidth hvrtextarea "
                                                placeholder="Please issue"
                                                v-model="scope.row.consultation">
                                                </el-input> -->
                                        <!-- <el-select v-model="scope.row.consultation" placeholder="Classification" class="inline-input fullwidth hvrtextarea ">
                                            <el-option v-for="item in consultationarray" :key="item.value" :label="item.name"
                                                :value="item.value">
                                            </el-option>
                                        </el-select> -->
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="issue"
                                        label="Location within documentation"
                                        width="200">
                                        <template slot-scope="scope">
                                            <div class="textstyle">{{ scope.row.documentlocation}}</div>
                                            <!-- <el-input
                                                type="textarea"
                                                :rows="5"
                                                class="inline-input fullwidth hvrtextarea "
                                                placeholder="Please issue"
                                                v-model="scope.row.consultation">
                                                </el-input> -->
                                        <!-- <el-select v-model="scope.row.consultation" placeholder="Classification" class="inline-input fullwidth hvrtextarea ">
                                            <el-option v-for="item in consultationarray" :key="item.value" :label="item.name"
                                                :value="item.value">
                                            </el-option>
                                        </el-select> -->
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="resolvedon"
                                        label="Snip">
                                        <template slot-scope="scope">
                                            <span style="width: 77%;" class="left">
                                                        <span v-for="svi in scope.row.ssv" :key="svi.id">
                                                            <el-popover placement="bottom" width="200" v-model="svi.mousehover" trigger="hover" >
                                                                <div @click="showpreview(svi)">
                                                                    <span class="previewbox" v-if="svi.extention == 'xlsx'">
                                                                        <div class="UR-Link fontsize12">
                                                                            {{ svi.name }}
                                                                        </div>
                                                                        <div class="center">
                                                                            <i class="el-icon-document iconsize90"></i>
                                                                        </div>
                                                                    </span>
                                                                    <span v-if="svi.extention">
                                                                    <span
                                                                        v-if="svi.extention.startsWith('jpg') || svi.extention.startsWith('jpeg')  || svi.extention.startsWith('gif')  || svi.extention.startsWith('png')">
                                                                        <img :src="svi.link" class="previewbox" />
                                                                    </span>
                                                                    <span v-if=" svi.extention.startsWith('mp4')">
                                                                        <video controls class="previewbox">
                                                                            <source :src="svi.url" type="video/mp4">
                                                                            Your browser does not support the video tag.
                                                                        </video>
                                                                    </span>
                                                                    <span v-if=" svi.extention.startsWith('mp3')">
                                                                        <audio controls class="previewbox">
                                                                            <source :src="svi.link" type="audio/ogg">
                                                                            <source :src="svi.link" type="audio/mpeg">
                                                                            Your browser does not support the audio element.
                                                                        </audio>
                                                                    </span>
                                                                </span>
                                                                </div>
                                                                <span slot="reference" class="UR-Link marginl5 marginright5px fontsize12 " 
                                                                    @mouseenter="previewsnip(svi)"> <el-tag size="mini" style="white-space: normal; height: 100% !important;"> {{ svi.name }} </el-tag></span>
                                                            </el-popover>
                                                        </span>
                                                    </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="interimaction"
                                        label="Interim Action">
                                        <template slot-scope="scope">
                                            <div class="textstyle"> {{ scope.row.interimaction}}</div>
                                            <!-- <el-input
                                                type="textarea"
                                                :rows="5"
                                                class="inline-input fullwidth hvrtextarea "
                                                placeholder="Please issue"
                                                v-model="scope.row.interimaction">
                                                </el-input> -->
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="requiredaction"
                                        label="Required Action">
                                        <template slot-scope="scope">
                                            <div class="textstyle"> {{ scope.row.requiredaction}} </div>
                                            <!-- <el-input
                                                type="textarea"
                                                :rows="5"
                                                class="inline-input fullwidth hvrtextarea "
                                                placeholder="Please issue"
                                                v-model="scope.row.requiredaction">
                                                </el-input> -->
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="response"
                                        label="Response">
                                        <template slot-scope="scope">
                                            <el-tooltip style="height: 100%"  effect="dark" :disabled="dateformat(scope.row.responseon) == ''" :content="dateformat(scope.row.responseon)" placement="top">
                                                <div v-if="canshowresponse == false">
                                                    <div class="textstyle">{{ scope.row.response }}</div>
                                                </div>
                                                <div v-else>
                                                    <el-input
                                                        :id="'comms_rfireport_response'+'_'+sv.$index+'_'+rfi.$index+'_'+scope.$index"
                                                        type="textarea"
                                                        :rows="3"
                                                        :class="scope.row.rfiversionid == rficurrontreportversion.id ? 'dark-black-input' : ''"
                                                        class="inline-input fullwidth  "
                                                        placeholder="Add Response"
                                                        @change="responsechange(scope.row)"
                                                        v-model="scope.row.response">
                                                        </el-input>
                                                </div>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="actiontaken"
                                        label="Action Taken">
                                        <template slot-scope="scope">
                                            <el-tooltip  effect="dark" :content="'Action Taken By:'+ (scope.row.resolvedbyuser.loginname != undefined ? scope.row.resolvedbyuser.loginname : '') + '  '+ dateformat(scope.row.resolvedon)" placement="top">
                                                <div v-if="canshowresponse == false">
                                                    <div class="textstyle">{{ scope.row.actiontaken }}</div>
                                                </div>
                                                <div v-else>
                                            <el-input
                                                :id="'comms_rfireport_action_taken'+'_'+sv.$index+'_'+rfi.$index+'_'+scope.$index"    
                                                type="textarea"
                                                :rows="3"
                                                :class="scope.row.rfiversionid == rficurrontreportversion.id ? 'dark-red-input' : ''"
                                                class="inline-input fullwidth "
                                                placeholder="Add Action Taken"
                                                 @change="responsechange(scope.row)"
                                                v-model="scope.row.actiontaken">
                                                </el-input>
                                            </div>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>
                                    
                                    <el-table-column
                                        prop="consultation"
                                        width="50"
                                        label="Action">
                                        <template slot-scope="scope">
                                            <div style="text-align: center;" v-if="scope.row.rfiversionid == rficurrontreportversion.id">
                                                <el-button size="mini" class="padding7"  type="primary" title="Edit" :icon="editpermission == true ? 'el-icon-edit' : 'el-icon-view'"
                                                @click="editrfi(scope.$index, scope.row,rfi.row)"> </el-button>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="consultation"
                                        width="80"
                                        label="Do Not Send">
                                        <template slot-scope="scope">
                                            <div style="text-align: center;" >
                                                <!-- <el-button size="mini" class="padding7"  type="primary" title="Edit" :icon="editpermission == true ? 'el-icon-edit' : 'el-icon-view'"
                                                @click="editrfi(scope.$index, scope.row,rfi.row)"> </el-button> -->
                                                <el-checkbox :id="'comms_rfireport_response_donotsend'+'_'+sv.$index+'_'+rfi.$index+'_'+scope.$index"     :disabled="scope.row.rfiversionid != rficurrontreportversion.id" class="red-check-box" @change="changecheckedrfi(scope.row,'cansendrfi')"  v-model="scope.row.cansendrfi" style="color: red;"></el-checkbox>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="consultation"
                                        width="80"
                                        label="Checked">
                                        <template slot-scope="scope">
                                            <div style="text-align: center;" >
                                                <!-- <el-button size="mini" class="padding7"  type="primary" title="Edit" :icon="editpermission == true ? 'el-icon-edit' : 'el-icon-view'"
                                                @click="editrfi(scope.$index, scope.row,rfi.row)"> </el-button> -->
                                                <el-tooltip :disabled="scope.row.checkedrfiuser.loginname == undefined" popper-class="green-tooltip" :content="'Checked By:'+scope.row.checkedrfiuser.loginname + ' ' +dateformat(scope.row.checkedrfion)" placement="bottom" effect="light">
                                                    <el-checkbox :id="'comms_rfireport_response_checked'+'_'+sv.$index+'_'+rfi.$index+'_'+scope.$index"     :disabled="scope.row.rfiversionid != rficurrontreportversion.id " class="green-check-box" @change="changecheckedrfi(scope.row,'checkedrfi')"  v-model="scope.row.checkedrfi"></el-checkbox>
                                                
                                                <!-- <span v-if="scope.row.checkedrfiuser.loginname != undefined" 
                                                style="color: green;
                                                font-size: 85%;
                                                font-weight: bold;
                                                white-space: pre-line">
                                                    <br/>
                                                    Checked By
                                                    <br/>
                                                    {{ scope.row.checkedrfiuser.loginname }}
                                                </span> -->
                                            </el-tooltip>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    </el-table>
                            </template>
                            </el-table-column>
                        <el-table-column
                        
                        prop="section"
                        label="Section">
                        <template slot-scope="scope">
                            <!-- <div v-if="sv.row."> -->
                                {{ scope.row.section }}
                            <!-- </div> -->
                        </template>
                    </el-table-column>
                        </el-table>
                </template>
            </el-table-column>
        <el-table-column
        prop="section.section"
        label="Section Name">
        <template slot-scope="scope">
            {{ scope.row.section.section }}
        </template>
      </el-table-column>
        </el-table>
        
      <!-- </template>
    </el-table-column>
      <el-table-column
        prop="name"
        label="Part Name">
      </el-table-column>
      </el-table> -->

        
    </el-row>
    <CustomPageDraggable style="    width: 1064px;height: 514px;left: 877px;top: 426px;" :isvisible="showrfilibrary">
          <i @click="showrfilibrary = false" class="el-icon-close right"></i>
          <projectrfi @addtoproject="addtoproject" ></projectrfi>
      </CustomPageDraggable>
    <CustomPageDraggable style="width: 640px;height:550px;" :isvisible="showrfi">
                            <i @click="showrfi = false" class="el-icon-close right"></i>
            <rfientryform @rfisaveed="rfisaveed" :nrmsectionprops="nrmsectionedit" :rfiid="rfiid" :projectamosdetailid="projectamosdetailid" :projecttemplatedetailid="projecttemplatedetailid"></rfientryform>
        </CustomPageDraggable> 
        <show-media @deleteMedia="deleteMedia" ref="showmedia"></show-media>
</div>
</template>
<script>
import projectrfi from "./projectrfi.vue";
import selectpart from "./selectpart.vue";
import CustomPageDraggable from './CustomPageDraggable.vue'
import rfientryform from './rfientryform.vue'
import moment from "moment";
import showmedia from "./showmedia.vue";
export default {
    data() {
        return {
            showrfilibrary:false,
            directionids:[
                "comms_rfireport_response",
                "comms_rfireport_action_taken",
                "comms_rfireport_response_donotsend",
                "comms_rfireport_response_checked",
            ],
            cansave:true,
            canshowresponse:false,
            rficurrontreportversion:null,
            rfireportversion:null,
            expandedrows:[],
            canshowreport:false,
            rfisections:[],
            ProjectParts:[],
            projectPartId:null,
            consultationarray: [
                { value: 'Urgent Attention Required', name: 'Urgent Attention Required' },
                { value: 'Outstanding Risk Item', name: 'Outstanding Risk Item' },
                { value: 'Resolved', name: 'Resolved' }
            ],
            sections:[],
            Similar: {
                projectPlaceholder: '',
                partPlaceholder: '',
                lastsavedshow: false,
                projectdisabled:false,
                partdisabled:false,
                partstyle:{ width: '289px', 'padding': '3px 0px 3px 3px' }
            },
            extentions:[
                'jpg',
                'jpeg',
                'gif',
                'png',
                'mp4',
                'mp3',
            ],
            importfile:{
                isimport:false
            },
            showrfi:false,
            nrmsection:'',
            projectamosdetailid:null,
            projecttemplatedetailid:null,
            tabledata: [],
            consultationarray: [
                { value: 'Urgent Attention Required', name: 'Urgent Attention Required' },
                { value: 'Outstanding Risk Item', name: 'Outstanding Risk Item' },
                { value: 'Resolved', name: 'Resolved' }
            ],
            consultationfilter:'',
            Resolvedfilter:'',
            versions:[],
            // partid:null,
            attachments: [],
            dateselect1: new Date(),
            dateselect2: new Date(),
            form: {},
            nrmsectionedit:null,
            rfiid:null,
            spell: false,
            custom: {
                rowcol: 'el-row',
                projectsid: null,
            },
            project: {},
            sectionid: null,
            rfireportversions:[],
        };
    },
    computed:{
        rfireportversionformat(){
            return function(data){
                if(data){
                    if(data <= 9){
                     return "V0"+data;
                    }else{
                        return "V"+data;
                    }
                }
            }
        },
        canexportreport(){
            if(this.project.usepart == 1){
                return true;
            }else{
                if(this.projectPartId != null){
                   return true; 
                }else{
                    return false;
                }
            }
        },
        // getprojectsection(){
        //     var newarray = []
        //     if(this.partid == null){
        //         newarray = this.sections.filter(x  => x.projectPartId == 0);
        //     }else{
        //         newarray = this.sections.filter(x  => x.projectPartId == this.partid);
        //     }
        //     var tsnew  =  newarray.sort((a,b) => (a.sectiondisplay > b.sectiondisplay) ? 1 : ((b.sectiondisplay > a.sectiondisplay) ? -1 : 0))
        //     if(tsnew.length > 0){
        //         tsnew.unshift({sectiondisplay:'General Items',id:0})
        //         tsnew.unshift({sectiondisplay:'All',id:null});
        //     }
        //     return tsnew;
        // },
        getsectctionview(){
            return function(data){
                if(data.isgeneralitems == true){
                    return [{section:"",rfis:data.rfigeneralitems}];
                }else{
                    return data.sections;
                }
            }
        },
        dateformat() {
            return function(data){
                if(data){
                    var dt = new Date(data);
                    return moment(dt).format("DD-MMM-YYYY HH:mm");
                }else{
                    return "";
                }
            }
        },
    },
    components: {
        selectpart: selectpart,
        CustomPageDraggable:CustomPageDraggable,
        rfientryform:rfientryform,
        'show-media': showmedia,
        "projectrfi":projectrfi
    },
    methods: {
        addtoproject(data){
            this.showrfilibrary = false;
            if(data != undefined && data.length > 0){
                var obj = {
                    projectid:this.project.id,
                    projectpartid:this.part.id,
                    librarys:data
                }
                this.showloading();
                this.addrfitoproject(obj).then(()=>{
                    this.hideloading();
                    
                    this.getviewreport();
                });
            }
        },
        addrfilibrary(){
            this.showrfilibrary = true;
        },
        getcellmatcheddirection(direction, directionidvalue) {
            let matcheddirection = direction.directioncelldetails.find(x => x.pagecell.cellname == directionidvalue);
            let dir = JSON.parse(JSON.stringify(direction));
            if (matcheddirection) {
                dir.includerule = matcheddirection.includerule;
                dir.excluderule = matcheddirection.excluderule;
                dir.formatrule = matcheddirection.formatrule;
                dir.pagecell = matcheddirection.pagecell;
                dir.pagecellid = matcheddirection.pagecellid;
                dir.checkvalue = matcheddirection.checkvalue;
                dir.operation = matcheddirection.operation;
                dir.celltype = matcheddirection.pagecell.celltype;
                dir.objectpath = matcheddirection.pagecell.objectpath;
                return dir;
            }
        },
        multicelllocationrulesmatch(celldirections, direction, errors, datarow) {
            let count = 0;
            for (let i = 0; i < celldirections.length; i++) {
                let celldir = celldirections[i];
                let celldirectiondata = null;
                let cellvalue = null;
                
                if (celldir.cellname == "response" && celldir.response) {
                    celldirectiondata = celldir.response;
                    cellvalue = datarow.response;
                }

                else if (celldir.cellname == "actiontaken" && celldir.response) {
                    celldirectiondata = celldir.response;
                    cellvalue = datarow.actiontaken;
                }
                else if (celldir.cellname == "cansendrfi" && celldir.response) {
                    celldirectiondata = celldir.response;
                    cellvalue = datarow.cansendrfi;
                }
                else if (celldir.cellname == "checkedrfi" && celldir.response) {
                    celldirectiondata = celldir.response;
                    cellvalue = datarow.cansendrfi;
                }
                if (celldirectiondata) {
                    // const  dr = this.getformat(celldirectiondata);
                    let ddata = this.getCellValueAndRegexRules(celldirectiondata, cellvalue);
                    
                    if (cellvalue != undefined && this.$directionutility.directionvaluematch(ddata) == false) {
                        count++;
                    }
                }
            }
            // if(count > 0){
            //     return true;
            // }else{
            //     return false;
            // }
            if (direction.directioncelldetails.length == count) {  
            //direction.directioncelldetails.length == count
                errors.forEach(x => {
                    x.ismatchedrulecell = true;
                });
                return true;
            }
            else {
                return false;
            }
        },
        getCellValueAndRegexRules(data, cellvalue) {
            if (data.includerule != null && data.includerule != "") {
                data.inc_pattern = new RegExp(data.includerule, "i");
            }

            if (data.excluderule != null && data.excluderule != "") {
                data.exc_pattern = new RegExp(data.excluderule, "i");
            }

            if (data.formatrule != null && data.formatrule != "") {
                data.format_pattern = new RegExp(data.formatrule, "i");
            }

            data.enteredvalue = cellvalue;

            return data;
         },
         getformat(dr){
           const data = {};
           var inc_pattern = null;
           var exc_pattern = null;
           var format_pattern = null;
           if(dr.includerule != null && dr.includerule != ""){
               inc_pattern = new RegExp(dr.includerule,"i");
           }
           if(dr.excluderule != null && dr.excluderule != ""){
               exc_pattern = new RegExp(dr.excluderule,"i");
           }
           if(dr.formatrule != null && dr.formatrule != ""){
               format_pattern = new RegExp(dr.formatrule,"i");
           }
           data.format_pattern = format_pattern;
           data.inc_pattern = inc_pattern;
           data.exc_pattern = exc_pattern;
           return data;
         },
         getdirectionoption(FormData,dr, checkstr, isgeneralitems,uniqueid,sectionindex,subsectionindex,rfiindex,sectionexpandid) {
            const option = JSON.parse(JSON.stringify({
                visible: true,
                sectionname: "",
                isedit: false,
                id: FormData.id,
                boxtype: "Small 2 Part Pop up",
                content: FormData.message,
                mediacontent: FormData.mediacontent,
                link: FormData.link,
                offercloseoption: FormData.offercloseoption,
                heading: FormData.directionname,
                directiondegree: FormData.directiondegree,
                direction: FormData,
                usedata: true,
                enteredvalue: FormData.enteredvalue,
                celltype: FormData.celltype,
                objectpath: FormData.objectpath,
                checkvalue: FormData.checkvalue,
                operation: FormData.operation,

            }));
            this.$set(option, "uniqueid", uniqueid)
            this.$set(option, "sectionindex", sectionindex)
            this.$set(option, "subsectionindex", subsectionindex)
            this.$set(option, "rfiindex", rfiindex)
            this.$set(option, "isgeneralitems", isgeneralitems)
            this.$set(option, "sectionexpandid", sectionexpandid)

            Object.assign(option, dr);
            this.$set(option, 'checkstr', checkstr)
            option.validatedirectionsingle = (data) => { this.validatedirectionsingle(data) }
            this.$set(option, "elementid", `${FormData.pagecell.cellname}_${sectionindex}_${subsectionindex}_${rfiindex}`);
            return option;
        },
        validatedirection(directions,callback){
            var errors = [];
            var uniqueid = 1;
            var pageindex = 0;
            var sectionexpandids = [];
            for (let i = 0; i < directions.length; i++) {
                const direction = directions[i];

               var response = this.getcellmatcheddirection(direction, this.directionids[0]); // direction.find(x => x.pagecell.cellname == this.directionids[0]);
               var actiontaken = this.getcellmatcheddirection(direction, this.directionids[1]); // direction.find(x => x.pagecell.cellname == this.directionids[1]);
               var cansendrfi = this.getcellmatcheddirection(direction, this.directionids[2]); // direction.find(x => x.pagecell.cellname == this.directionids[1]);
               var checkedrfi = this.getcellmatcheddirection(direction, this.directionids[3]); // direction.find(x => x.pagecell.cellname == this.directionids[1]);
               let cellobjarray = [
                    { cellname: "response", response: response },
                    { cellname: "actiontaken", response: actiontaken },
                    { cellname: "cansendrfi", response: cansendrfi },
                    { cellname: "checkedrfi", response: checkedrfi },
                ];
                for (let sectionindex = 0; sectionindex < this.tabledata.length; sectionindex++) {
                    const section = this.tabledata[sectionindex];
                    if(section.isgeneralitems == true){
                        let subsectionindex = 0;
                        for (let rfiindex = 0; rfiindex < section.rfigeneralitems.length; rfiindex++) {
                            const rfi = section.rfigeneralitems[rfiindex];
                            let iscellrulesmatched = false;
                            if(response){
                                iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors, rfi);
                                let dr = this.getformat(response);
                                let ddata = this.getCellValueAndRegexRules(response, rfi.response);
                                const option = this.getdirectionoption(response,dr,'response',true,uniqueid,sectionindex,subsectionindex,rfiindex,section.expandid);
                                if(this.$directionutility.directionvaluematch(ddata) == false){
                                    if (iscellrulesmatched == false) {
                                        option.ismatchedrulecell = false;
                                    }else{
                                        option.ismatchedrulecell = true;
                                        sectionexpandids.push(section.expandid);
                                    }
                                    errors.push(option);
                                    uniqueid ++;
                                }
                            }
                            if(actiontaken){
                                iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors, rfi);
                                let dr = this.getformat(actiontaken);
                                let ddata = this.getCellValueAndRegexRules(actiontaken, rfi.actiontaken);
                                const option = this.getdirectionoption(actiontaken,dr,'actiontaken',true,uniqueid,sectionindex,subsectionindex,rfiindex,section.expandid);
                                if(this.$directionutility.directionvaluematch(ddata) == false){
                                    if (iscellrulesmatched == false) {
                                            option.ismatchedrulecell = false;
                                        }else{
                                            option.ismatchedrulecell = true;
                                            sectionexpandids.push(section.expandid);
                                        }
                                    errors.push(option);
                                    uniqueid ++;
                                }
                            }
                            if(rfi.rfiversionid == this.rficurrontreportversion.id){
                                if(cansendrfi){
                                    iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors, rfi);
                                    let dr = this.getformat(cansendrfi);
                                    let ddata = this.getCellValueAndRegexRules(cansendrfi, rfi.cansendrfi);
                                    const option = this.getdirectionoption(cansendrfi,dr,'cansendrfi',true,uniqueid,sectionindex,subsectionindex,rfiindex,section.expandid);
                                    if(this.$directionutility.directionvaluematch(ddata) == false){
                                        if (iscellrulesmatched == false) {
                                                option.ismatchedrulecell = false;
                                            }else{
                                                option.ismatchedrulecell = true;
                                                sectionexpandids.push(section.expandid);
                                            }
                                        errors.push(option);
                                        uniqueid ++;
                                    }
                                }
                                if(checkedrfi){
                                    iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors, rfi);
                                    let dr = this.getformat(checkedrfi);
                                    let ddata = this.getCellValueAndRegexRules(checkedrfi, rfi.checkedrfi);
                                    const option = this.getdirectionoption(checkedrfi,dr,'checkedrfi',true,uniqueid,sectionindex,subsectionindex,rfiindex,section.expandid);
                                    if(this.$directionutility.directionvaluematch(ddata) == false){
                                        if (iscellrulesmatched == false) {
                                                option.ismatchedrulecell = false;
                                            }else{
                                                option.ismatchedrulecell = true;
                                                sectionexpandids.push(section.expandid);
                                            }
                                        errors.push(option);
                                        uniqueid ++;
                                    }
                                }
                            }
                        }
                    }else{
                        for (let subsectionindex = 0; subsectionindex < section.sections.length; subsectionindex++) {
                            const subsection = section.sections[subsectionindex];
                            for (let rfiindex = 0; rfiindex < subsection.rfis.length; rfiindex++) {
                                const rfi = subsection.rfis[rfiindex];
                                let iscellrulesmatched = false;
                                if(response){
                                    iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors, rfi);
                                    let dr = this.getformat(response);
                                    let ddata = this.getCellValueAndRegexRules(response, rfi.response);
                                    const option = this.getdirectionoption(response,dr,'response',false,uniqueid,sectionindex,subsectionindex,rfiindex,section.expandid);
                                    if(this.$directionutility.directionvaluematch(ddata) == false){
                                        if (iscellrulesmatched == false) {
                                            option.ismatchedrulecell = false;
                                        }else{
                                            option.ismatchedrulecell = true;
                                            sectionexpandids.push(section.expandid);
                                        }
                                        errors.push(option);
                                        uniqueid ++;
                                    }
                                }
                                if(actiontaken){
                                    iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors, rfi);
                                    let dr = this.getformat(actiontaken);
                                    let ddata = this.getCellValueAndRegexRules(actiontaken, rfi.actiontaken);
                                    const option = this.getdirectionoption(actiontaken,dr,'actiontaken',false,uniqueid,sectionindex,subsectionindex,rfiindex,section.expandid);
                                    if(this.$directionutility.directionvaluematch(ddata) == false){
                                            if (iscellrulesmatched == false) {
                                                option.ismatchedrulecell = false;
                                            }else{
                                                option.ismatchedrulecell = true;
                                                sectionexpandids.push(section.expandid);
                                            }
                                            errors.push(option);
                                            uniqueid ++;
                                    }
                                }
                                if(rfi.rfiversionid == this.rficurrontreportversion.id){
                                if(cansendrfi){
                                    iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors, rfi);
                                    let dr = this.getformat(cansendrfi);
                                    let ddata = this.getCellValueAndRegexRules(cansendrfi, rfi.cansendrfi);
                                    const option = this.getdirectionoption(cansendrfi,dr,'cansendrfi',false,uniqueid,sectionindex,subsectionindex,rfiindex,section.expandid);
                                    if(this.$directionutility.directionvaluematch(ddata) == false){
                                        if (iscellrulesmatched == false) {
                                                option.ismatchedrulecell = false;
                                            }else{
                                                option.ismatchedrulecell = true;
                                                sectionexpandids.push(section.expandid);
                                            }
                                        errors.push(option);
                                        uniqueid ++;
                                    }
                                }
                                if(checkedrfi){
                                    iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors, rfi);
                                    let dr = this.getformat(checkedrfi);
                                    let ddata = this.getCellValueAndRegexRules(checkedrfi, rfi.checkedrfi);
                                    const option = this.getdirectionoption(checkedrfi,dr,'checkedrfi',false,uniqueid,sectionindex,subsectionindex,rfiindex,section.expandid);
                                    if(this.$directionutility.directionvaluematch(ddata) == false){
                                        if (iscellrulesmatched == false) {
                                                option.ismatchedrulecell = false;
                                            }else{
                                                option.ismatchedrulecell = true;
                                                sectionexpandids.push(section.expandid);
                                            }
                                        errors.push(option);
                                        uniqueid ++;
                                    }
                                }
                            }
                            }
                        }
                    }
                }
            }
            if(sectionexpandids.length > 0){
                this.expandedrows = [];
                this.expandedrows.push(...sectionexpandids); 
            }
                errors = errors.filter(x => x.ismatchedrulecell == true);
               if(errors.length > 0){
                 this.canshowresponse = true;
                //    if(this.expandedrows.length <= 0){
                //         this.expandcollapse();
                //     }
                    this.$store.state.directionsdialogue = errors;
                    this.$store.state.currentdirectionindex = 0;
                    this.$store.state.currentdirection = errors[0];
                }else{
                    callback();
                }
        },
        validatedirectionsingle(data){
            
            this.expandedrows = [];
            this.expandedrows.push(data.sectionexpandid);
            this.canshowresponse = true;
            var newcheckval = undefined;
            if(data.isgeneralitems == true){
                if(this.tabledata[data.sectionindex].rfigeneralitems[data.rfiindex] != undefined){
                  newcheckval = this.tabledata[data.sectionindex].rfigeneralitems[data.rfiindex][data.checkstr];
                }
            }else{
                if(this.tabledata[data.sectionindex].sections[data.subsectionindex].rfis[data.rfiindex] != undefined){
                  newcheckval = this.tabledata[data.sectionindex].sections[data.subsectionindex].rfis[data.rfiindex][data.checkstr];
                }
            }
            //  newcheckval = this.getPropertyByString(this.detailform.projecttemplates[data.fileindx].projecttemplatedetails[data.dataindex],data.checkstr);
             if(newcheckval != undefined){
                 data.value = newcheckval;
                 data.enteredvalue = newcheckval;
             }else{
                data.value = "";
                data.enteredvalue = "";
             }
        },
        getsection(){
                // Get NRM section for each page
                if(false){
                    // return this.detailform.section;
                }else{
                    return "";
                }
        },
        cellchanged(cellaction,callback,cancall){
            if(cancall){
                callback();
                this.canmatchcellid(()=>{},cellaction,this.getsection(),true,(array1)=>{this.validatedirection(array1,callback)} );
            }else{
                this.canmatchcellid(callback,cellaction,this.getsection(),true,(array1)=>{this.validatedirection(array1,callback)});
            }
        },
        addresponses(){
            this.canshowresponse  = this.canshowresponse == true ? false : true;
        },
        changecheckedrfi(row,key){
            if(key == "checkedrfi"){
                row.cansendrfi = false;
            }else{
                row.checkedrfi = false;
            }
            // if(key == "checkedrfi"){
                if(row.checkedrfi == true){
                    row.checkedrfion = new Date();
                    row.checkedrfiby = this.$store.state.user.id;
                }else{
                    row.checkedrfion = null;
                    row.checkedrfiby = null;
                }
            // }
            row.responseadd = true;
        },
        tablecellClassName(row){
            
            var newobj = {};
            if(row.row.rfiversionid == this.rficurrontreportversion.id){
                if(row.column.label == "Action Taken"){
                    newobj.color = 'red';
                    // return {  
                    //     // background: 'gray',
                    //     color: 'red'
                    // };   
                }else{
                    newobj.color = 'black';
                }
                // return {  
                //     // background: 'gray',
                //     color: 'black',
                // };
            }
            else{
                newobj.color = '#909399';
            }
            if(row.row.strikethrough == true){
                newobj["text-decoration"] = 'line-through';
            }
            return newobj;
            // else{
            //     return {
            //         'text-decoration': 'line-through'
            //     }
            // }
        },
        tableRowClassName(row){
            if(row.row.rfiversionid == this.rficurrontreportversion.id){
                return "";
            }
            // return "gray-black";
        },
        savereportversion(){

            this.$confirm('Are you sure you want to create a new RFI version?', 'Confirm', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
            }).then(() => {

            this.showloading();
            var obj = {
                projectpartid: this.projectPartId == -1 ? null : this.projectPartId,
                projectid:this.project.id,
                version:0
            }
            if(this.rfireportversions.length <= 0){
                obj.version = 1;
            }else{
                obj.version = ((this.rfireportversions[this.rfireportversions.length - 1].version) + 1)
            }
            this.$http.post("RFI/savereportversion",obj )
                .then((response) => {
                    this.hideloading();
                    this.$message({
                        showClose: true,
                        message: "RFI version save successfully!",
                        type: "success",
                    });
                    this.getviewreport();
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                    this.hideloading();
                });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Creation of new RFI version cancelled.'
                    });          
            });
        },
        responsechange(row){
            row.responseadd = true;
        },
        selectProject: function (item) {
            
                this.$store.state.project = JSON.parse(JSON.stringify(item));
            this.project = item;
            this.sectionid = undefined;
            // this.sections = [];
            if(this.project.usepart === undefined || this.project.usepart == null){
                this.project.usepart = 0;
            }
            this.getSections();
        },
        getSections: function () {
            this.$http
            .post("project/getsections", this.project)
            .then((response) => {
            //   var json = JSON.parse(response.data);
            // this.partsections = response.data.projectsectionsview;
            this.ProjectParts = response.data.projectparts;
            if(this.project.usepart == 1){
                this.ProjectParts = [];
                this.ProjectParts.push({ id: -1, name: "All" });
            }else{
                if (this.ProjectParts.length == 0 && this.project.projectname != "") {
                    this.ProjectParts.unshift({ id: 0, name: "Single Bill" });
                }
            }
            this.getCurrentPart();
            })
            .catch((err) => {
            this.$bus.$emit("error", err);
            this.loading = false;
            });
        },
        getCurrentPart(){
            if(this.project.usepart == 1){
                this.$set(this,'projectPartId',-1);
                this.sections = this.rfisections;
                this.changepart(-1);
                // this.projectPartid = -1;
            }else{      
                this.projectPartid = null;
                if (this.$store.state.partname) {
                    var part = this.ProjectParts.find(
                    (s) => s.name == this.$store.state.partname
                    );
                    if (part) {
                        this.projectPartId = part.id;
                        this.part = part;
                        this.changepart(part.id);
                    }
                }
                if (this.projectPartid == null) {
                    if (this.$store.state.projectpart) {
                        var part = this.ProjectParts.find(
                            (s) => s.id == this.$store.state.projectpart.id
                        );
                        if (part) {
                            this.projectPartId = part.id;
                            this.part = part;
                            this.changepart(part.id);
                        }
                    }
                }
            }
        },
        changepart(event){
            this.rfireportversion = null;
            this.sectionid = undefined;
            this.versions = [];
            this.canshowreport = true;
            if (event == 0) {
                this.projectPartId = 0;
                this.sections = this.rfisections;
               
            }else if(event == -1){
                this.$set(this,'projectPartId',-1);
               
            }
            else {
               
                this.sections = this.rfisections;
            }
            var projectpart = this.ProjectParts.find((p) => p.id == event);
            this.part = projectpart;
            this.$store.state.projectpart = projectpart;
            this.getviewreport();
            this.getCurrentSection();
        },
        getCurrentSection: function () {
            this.sectionid = undefined;
            if (this.$store.state.rfisectionid) {
                var section = this.sections.find(
                (s) => s.id == this.$store.state.rfisectionid
                );
                if (section) {
                    this.sectionid = section.id;
                    this.sectionchange(this.sectionid)
                    this.project.section = section;
                    // this.lastsavedbyname = section.lastsavedbyname;
                }
            }
            // this.project.projectsections = this.sections;
        },
        sectionchange(event){
        //   if(event >= 0){
        //     var issection = this.sections.find(x => x.id == event);
        //     if(issection != undefined){
        //         this.$store.state.rfisectionid = issection.sectionid;
        //     }
        //  }
        if(event == ""){
            this.sectionid = undefined;
            this.$store.state.rfisectionid = null;
        }else{
            this.sectionid = event;
            this.$store.state.rfisectionid = event;
        }
        this.nrmsection = null;
        this.getRFIversions();
        this.getviewreport();

        },
        closeproject(){
            this.project = {
                id: 0,
                projectname: "",
                library: "",
            };
            this.canshowreport = true;
            this.tabledata = [];
            this.canshowreport = false;
            // this.lastsavedbyname = null;
            this.sectionid = undefined;
            this.projectPartId = null;
            this.sections = [];
            this.part = null;
            // this.partsections = [];
            this.ProjectParts = [];
            this.versions = [];
            // this.form = {};
            // this.contractoractions = "";
            // this.newnrmsection = null;
            // this.isnrmshow = false;
            // this.isedit = false;
        },
        previewsnip(i) {
            i.link = i.link.trim();
            i.extention = i.name.substring(i.name.lastIndexOf('.') + 1).toLowerCase();
            if(i.link[i.link.length - 1] == "0"){
                i.link = `${i.link.substring(0, i.link.length - 1)}1`;    
            }
            i.mousehover = true;
            i.externalurl = `${i.link.substring(0, i.link.length - 1)}0`;
            if (i.extention === "mp4") {
                i.url = `${i.link.substring(0, i.link.length - 4)}raw=1`;
            }
        },
        showpreview(i) {
            i.mousehover = false;
            i.showdialog = true;
            this.$refs.showmedia.openmedia(i, 'selectedmedia');
        },
        deleteMedia(data) {
            this.showloading();
            this.$http.post("RFI/deletemedia", { id: data.id,tableid:data.tableid })
                .then((response) => {
                    this.hideloading();
                    this.$message({
                        showClose: true,
                        message: "Media deleted successfully!",
                        type: "success",
                    });
                    this.$refs.showmedia.CloseMedia();
                    // this.getsectionalviewimages();
                    this.getviewreport();
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                    this.hideloading();
                });
        },
        rfisaveed(){
            this.getviewreport();
        },
        editrfi(index,row,sv){
            this.rfiid = row.id;
            // this.$store.state.sectiondisplay =
            // this.nrmsectionedit = row.nrmsection;
            this.$store.state.sectiondisplay = sv.section;
            // this.nrmsectionedit = null;
            // this.projectamosdetailid = null;
            // this.projecttemplatedetailid = null;
            // if(row.projectamosdetailid ==null && row.projecttemplatedetailid == null){
            //     this.nrmsectionedit = row.nrmsection;
            // }else{
            //     this.projectamosdetailid = row.projectamosdetailid;
            //     this.projecttemplatedetailid = row.projecttemplatedetailid;
            // }
            this.showrfi = true;
        },
        customSort(a, b) {
            const segmentsA = a.nrmsection.split('.').map(Number);
            const segmentsB = b.nrmsection.split('.').map(Number);

            const length = Math.max(segmentsA.length, segmentsB.length);

            for (let i = 0; i < length; i++) {
                const numA = segmentsA[i] !== undefined && !isNaN(segmentsA[i]) ? segmentsA[i] : 0;
                const numB = segmentsB[i] !== undefined && !isNaN(segmentsA[i]) ? segmentsB[i] : 0;

                if (numA !== numB) {
                    return numA - numB;
                }
            }

            return 0;
        },
        getviewreport(){
            var obj = {
                // projectsectionid:this.sectionid,
                nrmsection:this.nrmsection,
                resolved:this.Resolvedfilter,
                consultation:this.consultationfilter,
                projectpartid: this.projectPartId == -1 ? null : this.projectPartId,
                projectid:this.project.id,
                usepart:this.project.usepart,
                
            }
            if(this.rfireportversion != "" && this.rfireportversion != null){
                obj.rfiversionid = this.rfireportversion;
            }else{
                obj.rfiversionid = null;
            }
            if(this.sectionid !== undefined){
                obj.rfisectionsid = this.sectionid; 
            }else{
                obj.rfisectionsid = -1;
            }
            this.showloading();
            this.$http.post("RFI/getviewreport",obj)
              .then((response) => {
              if(response.data){
                    this.expandedrows = [];
                    this.tabledata = response.data.rfisectionsviews;
                    this.rfireportversions = response.data.rfiversions;
                    this.rficurrontreportversion = response.data.currentrfiversion;
                    var isindex = this.tabledata.findIndex((x) => x.isgeneralitems == true && x.rfigeneralitems.length == 0);
                    if(isindex != -1){
                        this.tabledata.splice(isindex, 1);
                    }
                    this.tabledata =  this.tabledata.sort((a,b) => (a.section.section > b.section.section) ? 1 : ((b.section.section > a.section.section) ? -1 : 0))
                    for (let index = 0; index < this.tabledata.length; index++) {
                        const element = this.tabledata[index];
                        if(element){
                            element.expandid = index; 
                            if(element.isgeneralitems == true){
                                if(this.tabledata[index].rfigeneralitems){
                                    this.tabledata[index].rfigeneralitems =  element.rfigeneralitems.sort(this.customSort);// element.rfigeneralitems.sort((a,b) => (a.nrmsection > b.nrmsection) ? 1 : ((b.nrmsection > a.nrmsection) ? -1 : 0))
                                }
                            }else{
                                this.expandedrows.push(index)
                                if(element.sections){
                                    for (let j = 0; j < element.sections.length; j++) {
                                        const newj = element.sections[j];
                                        if(newj.rfis){
                                        element.sections[j].rfis = newj.rfis.sort(this.customSort); //newj.rfis.sort((a,b) => (a.nrmsection > b.nrmsection) ? 1 : ((b.nrmsection > a.nrmsection) ? -1 : 0))
                                        }
                                    }
                                    this.tabledata[index].sections = element.sections.sort((a,b) => (a.section > b.section) ? 1 : ((b.section > a.section) ? -1 : 0))
                                }
                            }
                        }
                    }
                }
                this.hideloading();
              })
              .catch((err) => {
                  this.$bus.$emit("error", err);
                  this.hideloading();
              });
        },
        deleteAttachment() {
            this.attachments = [];
        },
        addAttachment(file, fileList) {
        this.cansave = false;
            if (file.file.size > 10000 * 1024) {
                this.$alert(
                    "File size must be less than 10 mb",
                    "File Size exceeds the limit"
                );
                this.cansave = true;
                return;
            }

            // if (file.file.name.length >= 49) {
            //     var style = "<style> .el-message-box {width:30%;} </style>";
            //     this.$alert("File name should not exceed 45 characters." + style, "Length Limitation", {
            //         dangerouslyUseHTMLString: true,
            //     });
            //     return;
            // }
            const self = this;
            this.attachments = [file.file];
            var ext = file.file.name
                .substr(file.file.name.length - 4)
                .toLowerCase();
                var obj = {
                    projectid:self.project.id,
                    projectpartid: self.projectPartId == -1 ? null : self.projectPartId,
                    rfisectionsid:self.sectionid,
                    usepart:self.project.usepart,
                    xlsx:""
                }
            // this.importfile = obj;
            if (ext == "xlsx") {
                //var importdata = "";
                try {
                    var fileReader = new FileReader();
                    // projectschedule.name = file.file.name;
                    fileReader.onload = function (e) {
                        self.importfile.xlsx = fileReader.result;
                        self.importfile.isimport = true;
                        this.cansave = true;
                        // self.saverfireport(obj);
                    };
                    fileReader.readAsDataURL(this.attachments[0]);
                }catch(err) {
                    this.cansave = true;
                }
            }else{
                this.cansave = true;
            }
        },
        validationexport(){
            var warnings = [];
            this.tabledata.forEach(e => {
                if(e.isgeneralitems == true){
                    var str = "";
                    e.rfigeneralitems.forEach(rfie => {
                        if(rfie.checkedrfi == false && rfie.cansendrfi == false){
                            str += "<b> RFI:"+ rfie.nrmsection + "</b> please check the 'Do not send' or 'Checked' checkbox! </br>"; 
                        }
                    });
                    if(str != ""){
                        str = "<b> General Items </b> </br>" + str;
                        warnings.push(str);
                    }
                }else{
                    e.sections.forEach(ss => {
                        var str = "";
                        // str += "<b> "+ss.section +" </b> </br>"
                        ss.rfis.forEach(rfie => {
                             if(rfie.checkedrfi == false && rfie.cansendrfi == false){
                                 str += "<b> RFI:"+ rfie.nrmsection + "</b> please check the 'Do not send' or 'Checked' checkbox! </br>"; 
                             } 
                           });
                           if(str != ""){
                                str = "<b> "+ss.section +" </b> </br>" + str;
                                warnings.push(str);
                            } 
                    });
                }
            });

            if(warnings.length > 0){
                this.$confirm(warnings.join(" "), 'Warning', {
                    confirmButtonText: 'Export RFI',
                    cancelButtonText: 'Cancel',
                    dangerouslyUseHTMLString: true,
                    
                    customClass:'confirmmsgstyle'
                    }).then(() => {
                        this.exportxlsx();
                    }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Export RFI canceled'
                    });          
             });
            }else{
                this.exportxlsx();        
            }
        },
        exportxlsx(){
            const self = this;
            this.showloading();
            var obj = {
                    projectid:self.project.id,
                    projectpartid:self.projectPartId,
                    projectsectionid:self.sectionid,
                    usepart:self.project.usepart,
                }
            this.$http.post("RFI/exportRFIxlsx",obj,{ responseType: 'blob' })
                    .then((response) => {
                        this.hideloading();
                        var blob = response.data;
                       
                        var fileName = self.project.projectname.replace(/\./g, "") + " - RFI "+this.rfireportversionformat(this.rficurrontreportversion.version);
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = fileName;
                        link.click();
                    })
                    .catch((err) => {
                        this.$bus.$emit("error", err);
                        this.hideloading();
                    });
        },
        saverfireport(){
            this.showloading();
            var newrfis = [];
            this.tabledata.forEach(e => {
                if(e.isgeneralitems == true){
                    var isrfi = e.rfigeneralitems.filter(x => x.responseadd === true);
                    newrfis.push(...isrfi);
                }else{
                    e.sections.forEach(s => {
                        var isrfi = s.rfis.filter(x => x.responseadd === true);
                    newrfis.push(...isrfi);
                    });
                }
            });
            const self = this;
            self.importfile.rfis = newrfis; 
            self.importfile.projectid = self.project.id;
            self.importfile.projectpartid = self.projectPartId;
            this.$http.post("RFI/saverfireport",self.importfile)
                    .then((response) => {
                        self.importfile.xlsx = "";
                        self.importfile.isimport = false;
                        this.$message({
                        showClose: true,
                        message: response.data,
                        type: "success",
                    });
                        this.hideloading();
                        this.getviewreport();
                        this.attachments = [];
                    })
                    .catch((err) => {
                        this.$bus.$emit("error", err);
                        this.hideloading();
                        this.attachments = [];
                        this.importfile.xlsx = "";
                        this.importfile.isimport = false;
                    });
        },
        setdate2(name) {
            var cdate = null;
            if(name != "subtract"){
                 cdate = moment(this.dateselect2, "YYYY-MM-DD").add(1, 'days').toDate();;
            }else{
                 cdate = moment(this.dateselect2, "YYYY-MM-DD").subtract(1, 'days').toDate();;
            }
            this.dateselect2 = cdate;
        },
        setdate1(name) {
            var cdate = null;
            if(name != "subtract"){
                 cdate = moment(this.dateselect1, "YYYY-MM-DD").add(1, 'days').toDate();;
            }else{
                 cdate = moment(this.dateselect1, "YYYY-MM-DD").subtract(1, 'days').toDate();;
            }
            this.dateselect1 = cdate;
        },
        spellcheckMethod() {
            this.spell = this.spell == true ? false : true;
        },
        spellchack() {
            this.spell = this.spell == true ? false : true;
        },
        getRFIversions() {
            this.showloading();
            var obj = {
                projectid:this.project.id,
                rfisectionsid:this.sectionid,
                // projectpartid:this.projectPartId,
                // projectsectionid:this.sectionid,
                canhide:false,
                usepart:this.project.usepart
            }
            if(this.project.usepart == 1){
                obj.projectpartid = null;
            }else{
                if(this.projectPartId  > 0 && this.projectPartId != null){
                    obj.projectpartid = this.projectPartId;
                }else{
                    obj.projectpartid = 0;
                }
            }
            if(this.projectsection && this.projectsection.section != undefined && this.sectionid > 0){
                obj.section = this.projectsection.section
            }else{
                obj.section = "G";
            }
            this.$http.post("RFI/getRFIversions", obj)
                .then((response) => {
                    this.versions = response.data;
                    this.hideloading();
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                    this.hideloading();
                });
        },
        // cmpprojectsectionchange: function (Projectobj) {
        //     // this.project.id = Projectobj.project.id;
        //     this.project = Projectobj.project;
        //     this.project.projectpart = Projectobj.part;
        //     this.partid = Projectobj.part != null ? Projectobj.part.id : null;
        //     if (this.project.projectname == "") {
        //         this.sectionid = undefined;
        //         this.$store.state.projectsections = [];
        //     }
        //     this.sections = [];
        //     this.sections = this.$store.state.projectsections;
        //     if (this.sections.length > 0) {
        //         if(this.$store.state.sectiondisplay){
        //             var section = this.sections.find(
        //         (s) => s.sectiondisplay == this.$store.state.sectiondisplay
        //         );
        //         }
        //         if(section){
        //             this.sectionchange(section.id);
        //         }else{
        //             this.getRFIversions();
        //             this.getviewreport();
        //         }
        //         // this.sections.unshift({sectiondisplay:'General Items',id:0});
        //         // this.sections.unshift({sectiondisplay:'All',id:null});
        //     }else{
        //         this.tabledata = [];
        //         this.project = null;
        //         this.partid = null;
        //         this.sectionid = undefined;  
        //         this.sections = [];  
        //     }
        //     // if (Projectobj.sectionid) {
        //     //     this.project = Projectobj.project;
        //     //     this.partid = Projectobj.part.id;
        //     //     this.sectionid = Projectobj.sectionid;
        //     //     this.getRFIversions();
        //     //     this.getviewreport();
        //     // }else{
        //     //     this.tabledata = [];
        //     //     this.project = null;
        //     //     this.partid = null;
        //     //     this.sectionid = null;
        //     // }
        // },
    },
    created() {
        if(this.$store.state.rfisections.length <= 0){
            this.getrifsections().then((data)=>{
                this.rfisections = [];
                this.rfisections = JSON.parse(JSON.stringify(data));
                this.rfisections.unshift({section:'General Items',id:null})
            });
        }else{
            this.rfisections = [];
            this.rfisections = JSON.parse(JSON.stringify(this.$store.state.rfisections));
            this.rfisections.unshift({section:'General Items',id:null})
        }
        if (this.$store.state.project) {
           var project = JSON.parse(JSON.stringify(this.$store.state.project));
           this.selectProject(project);
         }
    }
};
</script>
<style>
.colstyle{
    margin: 0px 3px;
}
.gridcontainer{
    display: grid;
    grid-template-columns: auto auto auto auto;
}
.textstyle{
    white-space: break-spaces;
    word-break: break-word;
    vertical-align: top;
}
.el-table .hidden-row{
    display: none;
}
.rfitable .el-table{
    color: #484848;
    /* color: magenta; */
}
.el-table .gray-row {
    background: gray;
    color: white;
    vertical-align: top !important
  }
  .el-table .gray-black {
    vertical-align: top !important
  }
  .dark-black-input .el-textarea__inner
  {
    /* background: gray; */
    color: black;
  }
  .dark-red-input .el-textarea__inner
  {
    /* background: gray; */
    color: red;
  }
  /* .el-table .gray-row:hover {
    background: gray;
    color: white;
  } */
  .red-check-box .el-checkbox__inner{
    /* background-color: ; */
    border-color:red;
  }
  .red-check-box .el-checkbox__input.is-checked .el-checkbox__inner
  {
    background-color: red;
    border-color: red;
  }
  .red-check-box .el-checkbox__inner:hover
  {
    border-color: red;
  }
  

  .green-check-box .el-checkbox__inner{
    /* background-color: ; */
    border-color:green;
  }
  .green-check-box .el-checkbox__input.is-checked .el-checkbox__inner
  {
    background-color: green;
    border-color: green;
  }
  .green-check-box .el-checkbox__inner:hover
  {
    border-color: green;
  }
  .green-tooltip
  {
    color: green;
  }
  .confirmmsgstyle{
    width: 500px !important;
  }
</style>
